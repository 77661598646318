export default class Model {
  constructor() {
    this.input = {
      id: 0,
      deleted: false,
      name: "",
      nameKz: "",
      description: "",
      descriptionKz: "",
      parents: [0],
      hidden: false,
      orderOnPage: [0],
      image: null,
      promo: false,
      departmentId: null,
    };
    this.body = [];
    this.pages = {};
  }
  setInput(mode, data) {
    if (mode === "Сохранить") {
      let parents = [];
      let orderOnPage = [];
      if (data.parentMap) {
        parents = Object.keys(data.parentMap).map((key) => parseFloat(key));
        orderOnPage = Object.values(data.parentMap);
      }
      this.input.description = data.description;
      this.input.descriptionKz = data.descriptionKz || "";
      this.input.id = data.id;
      this.input.parents = parents;
      this.input.name = data.name || "";
      this.input.nameKz = data.nameKz || "";
      this.input.hidden = data.hidden;
      this.input.deleted = data.deleted;
      this.input.orderOnPage = orderOnPage;
      this.input.image = data.image?.fileUrl || "@/assets/placeholder.png";
      this.input.promo = data.promo;
      this.input.departmentId = data.departmentId;
    }
    return this.input;
  }
  setPages(data) {
    if (data) {
      this.pages = {
        count: parseFloat(data.totalPages) - 1,
        current: data.number,
        elements: data.totalElements,
      };
    }
    return this.pages;
  }
  setBody(data) {
    if (data) {
      for (let i = 0; i < data.content.length; i++) {
        let parents;
        let orderOnPage;
        if (data.content[i].parentMap) {
          parents = Object.keys(data.content[i].parentMap).join(";");
          orderOnPage = Object.values(data.content[i].parentMap).join(";");
        }

        this.body.push({
          fields: [
            {
              id: 1,
              name: data.content[i].id,
              type: "string",
            },
            {
              id: 2,
              name: data.content[i].name,
              type: "string",
            },
            {
              id: 3,
              name: data.content[i].hidden,
              type: "string",
            },
            {
              id: 4,
              name: data.content[i].description
                ? data.content[i].description.slice(0, 40)
                : null,
              type: "string",
            },
            {
              id: 5,
              name: parents,
              type: "string",
            },
            {
              id: 6,
              name: data.content[i].deleted,
              type: "string",
            },
            {
              id: 7,
              name: orderOnPage,
              type: "string",
            },
            {
              id: 8,
              name: data.content[i].promo,
              type: "string",
            },
            {
              id: 9,
              name:
                data.content[i].children !== null
                  ? data.content[i].children.map((x) => x.name).toString()
                  : null,
              type: "string",
            },
            {
              id: 10,
              name:
                data.content[i].image !== null
                  ? data.content[i].image.fileUrl
                  : "",
              type: "image",
            },
          ],
        });
      }
    }
    return this.body;
  }
}
